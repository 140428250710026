@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@400;500;600&family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  color: #1d1d1d;
  overflow-x: hidden;
  font-family: "Sk-Modernist";
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #979e9d;
}

.main_navbar {
  background: linear-gradient(
    134deg,
    rgba(207, 207, 207, 0.4) 0%,
    rgba(207, 207, 207, 0.24) 100%
  );
  backdrop-filter: blur(10.5px);
  z-index: 1000;
}

.main_navbar .navbar_right {
  display: flex;
  align-items: center;
}

.main_navbar ul {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
}

.main_navbar ul li {
  margin-right: 35px;
}

.main_navbar ul li a {
  color: #1d1d1f;
  font-family: Sk-Modernist;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
  margin: 0;
}

.main_navbar .offcanvas.offcanvas-end {
  transform: unset;
}

.main_navbar .offcanvas.offcanvas-end.show {
  min-height: 100vh;
}

.main_navbar .navbar-toggler {
  box-shadow: none;
}

.main_navbar .navbar-toggler-icon {
  filter: invert(1);
  box-shadow: none;
}

.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}

.navbar-sticky--on {
  margin-top: 0;
}

.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@font-face {
  font-family: "Clash Display";
  src: url("./assets/font/ClashDisplay-Medium.woff2") format("woff2"),
    url("./assets/font/ClashDisplay-Medium.woff") format("woff"),
    url("./assets/font/ClashDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Sk-Modernist";
  src: url("./assets/font/sk-modernist-bold-webfont.woff2") format("woff2"),
    url("./assets/font/sk-modernist-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sk-Modernist";
  src: url("./assets/font/sk-modernist-mono-webfont.woff2") format("woff2"),
    url("./assets/font/sk-modernist-mono-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sk-Modernist";
  src: url("./assets/font/sk-modernist-regular-webfont.woff2") format("woff2"),
    url("./assets/font/sk-modernist-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.main_navbar .mobileSocialNavbar{
  display: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Space Grotesk", sans-serif;
}

a.active {
  color: #f78e0e !important;
}
.primary_btn {
  border-radius: 100px;
  background: #1d1d1d;
  color: #fff;
  font-family: Sk-Modernist;
  font-size: 18px;
  padding: 10px 30px;
  border: 1px solid transparent;
  outline: 0;
}
.primary_btn:hover {
  background: transparent;
  color: #000;
  border: 1px solid #000;
}

.primary_btn_extra {
  border-radius: 100px;
  background: #1d1d1d;
  color: #fff;
  font-family: Sk-Modernist;
  font-size: 18px;
  padding: 10px 60px;
  border: 1px solid transparent;
  outline: 0;
}
.grey_btn,
.grey_btn:hover{
  cursor: default;
  background: rgb(204 204 204) !important;
  border: 0;
  color: #fff;
}
.mob_view {
  display: none;
}

.h2tag {
  color: #060a23;
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 15px;
}

.page_header {
  text-align: center;
  padding: 50px 0;
  padding-top: 200px;
  background: url("./assets/images/banbg.png") no-repeat center;
  background-size: 100% 100%;
  margin-top: -150px;
}

.page_header h1 {
  color: #1d1d1d;
  font-size: 62px;
  font-weight: 700;
  max-width: 80%;
  margin: 0 auto;
}

.page_header .banimg {
  margin: 80px 0;
}

.page_header .presale {
  border-radius: 140px;
  border: 1px solid #1d1d1d;
  max-width: 70%;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page_header .presale p {
  color: #000;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
}

.page_header .presale .comingsoon {
  border-radius: 100px;
  border: 0.5px solid #027a48;
  background: #ecfdf3;
  padding: 8px 20px;
  color: #027a48;
  font-size: 20px;
  font-weight: 700;
  font-family: "Sk-Modernist";
}

.page_header .header_box {
  padding: 30px;
  border-radius: 16px;
  border: 1px solid #1d1d1d;
  margin: 30px auto;
  padding-bottom: 60px;
  position: relative;
  max-width: 60%;
}
.page_header .header_box .flx_head {
  display: flex;
  justify-content: center;
  text-align: left;
}
.page_header .header_box .para {
  font-size: 20px;
  font-weight: 500;
  color: #1d1d1f;
  font-family: Sk-Modernist;
  font-size: 18px;
}

.page_header .header_box h3 {
  margin-bottom: 0;
  color: #060a23;
  font-family: Space Grotesk;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
.page_header .header_box p {
  font-size: 18px;
}
.page_header .header_box .right_box {
  padding: 0 50px;
}

.page_header .header_box .right_box .flx {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  margin-top: 20px;
}

.page_header .header_box .right_box .flx p {
  margin-bottom: 8px;
}

.page_header .header_box .right_box .progress-bar {
  border-radius: 10px;
  background: #1d1d1d;
  font-size: 13px;
  font-weight: 700;
  text-align: end;
  padding-right: 10px;
}

.page_header .header_box .right_box .progress {
  background: #fffcfc;
  border: 1px solid #9f9b9b;
  padding: 8px;
  height: 37px !important;
  border-radius: 25px !important;
}

.page_header .header_box .primary_btn {
  position: absolute;
  left: 0;
  right: 0;
  width: fit-content;
  margin: 0 auto;
  bottom: -27px;
}
.page_header .header_box .primary_btn:hover {
  background: #fff;
}
.page_header .header_box .form-group {
  margin: 0 auto 30px;
  width: 70%;
}
/* .page_header .header_box .input-group.rgt {
  margin-right: 30px;
} */
.page_header .header_box .form-control {
  border-color: #1d1d1d;
  box-shadow: none !important;
  border-radius: 30px;
  padding: 10px;
}
.flex_label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.page_header .header_box .input-group-text {
  border-radius: 0 30px 30px 0;
  background: #1d1d1d;
  color: #fff;
  border-color: #1d1d1d;
  font-size: 18px;
  padding: 0 15px;
  line-height: 0;
}
.page_header .header_box button.input-group-text {
  background: transparent;
  color: #1d1d1d;
}
.timer_panel {
  display: flex;
  /* justify-content: center; */
  text-align: center;
  position: relative;
  margin: 0 auto;
  padding: 0 100px;
}

.timer_time {
  font-size: 52px;
  text-align: center;
  color: #060a23;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 600;
}

span.timer_label {
  display: block;
  text-align: center;
  font-size: 18px;
  margin-top: -5px;
  font-weight: 400;
}

span.timer_label.sec {
  text-align: left;
  padding: 0 5px;
}

span.timer_dots {
  /* line-height: 82px; */
  font-size: 48px;
  color: #060a23;
  font-family: Assistant;
  font-weight: 700;
}

.timer_panel > span {
  margin: 0px 15px 0 0;
}

.timer_panel::before {
  content: "";
  background: #1d1d1d;
  width: 1px;
  height: 100%;
  right: -12px;
  position: absolute;
}

.rdt_Table {
  color: #000;
}

.table_sec {
  padding-top: 60px;
}
.table_sec .table_size {
  max-width: 90%;
  margin: 0 auto;
}
.rdt_Table {
  border: 1px solid #1d1d1f99;
}
.rdt_TableHeadRow {
  background: #fcfcfd !important;
  border-color: #f0f0f0 !important;
  font-size: 20px;
}
.rdt_TableRow {
  background: #fcfcfd !important;
  border-color: #f0f0f0 !important;
  font-size: 16px !important;
}

.about {
  background: #f7f9fc;
  padding: 50px 0;
  margin: 50px 0;
}

.about p {
  font-size: 20px;
}

.howbuy {
  padding: 50px 0;
}

.howbuy ul {
  padding: 50px 0;
  margin: 0;
  list-style-type: none;
  background: url("./assets/images/buysec_bg.png") no-repeat center;
  background-size: 60%;
}

.howbuy li {
  max-width: 65%;
  border: 1px solid rgba(29, 29, 31, 0.3);
  background: #f7f9fc;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.howbuy li:nth-child(even) {
  margin-left: auto;
}

.howbuy li span {
  border: 1px solid rgba(29, 29, 29, 0.5);
  border-radius: 50%;
  min-width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 35px;
  color: #000;
  font-family: "Clash Display";
  font-size: 24px;
  background: #fff;
}

.howbuy li p {
  color: rgba(29, 29, 31, 0.8);
  font-size: 22px;
  margin-bottom: 0;
}

.howbuy li p a {
  color: rgba(29, 29, 31, 0.8);
}

.chart {
  padding: 50px 0;
}

.chart ul {
  width: fit-content;
  margin: 0 auto;
  display: none;
}

.chart li {
  display: flex;
  align-items: center;
}

.chart li .box {
  border: 1px solid #000;
  background: #39cef3;
  width: 18px;
  height: 18px;
  margin-right: 15px;
}

.chart li:nth-child(2) .box {
  background: #9ad961;
}

.chart li:nth-child(4) .box {
  background: #ff6229;
}

.chart li:nth-child(3) .box {
  background: #4a5cf8;
}

.chart li:nth-child(5) .box {
  background: #f9aa2c;
}

.chart li:nth-child(6) .box {
  background: #ff447c;
}

.chart li p {
  color: #000;
  font-family: Space Grotesk;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

.chart li p span {
  color: #333;
  font-family: Sk-Modernist;
  font-size: 18px;
  font-weight: 400;
}

.roadmap {
  padding: 50px 0;
}

.roadmap .ctn {
  max-width: 40%;
  margin-bottom: 30px;
}

.roadmap .end {
  text-align: center;
  color: #3a4650;
  font-family: Assistant;
  font-size: 14px;
  margin-top: 20px;
}

.roadmap .roadmapbg {
  background: #f7f9fc;
  padding: 20px 0 40px;
}

.roadmap .box.bdrheight {
  height: 200px;
}

.roadmap .leftctn {
  position: absolute;
  top: -50px;
  left: -50px;
}

.roadmap .box {
  width: 320px;
  border-left: 1px solid #f78e0e;
  padding: 15px;
  height: 300px;
  position: relative;
}

.roadmap .box::before {
  content: "";
  position: absolute;
  background: radial-gradient(#f78e0e 37%, #f78e0e45 40%) no-repeat;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  top: -15px;
  left: -15px;
}

.roadmap .box.now::before {
  background: radial-gradient(#f78e0e 37%, #63636300 40%) no-repeat !important;
  box-shadow: 0 0px 10px 0 #f78e0e !important;
}

.roadmap .box.now::after {
  content: "";
  position: absolute;
  border: 1px #f78e0e dashed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 1px;
}

.roadmap .box h6 {
  font-family: Assistant;
  font-size: 18px;
  font-weight: 600;
}

.roadmap .box ol {
  padding-left: 17px;
}

.roadmap .slick-prev:before,
.roadmap .slick-next:before {
  color: #f78e0e;
}

.roadmap .slick-list {
  height: 600px;
  padding-left: 80px;
}

.roadmap .slick-slide {
  margin-left: -175px;
  width: 340px !important;
  outline: 0 !important;
}

.roadmap .slick-slide:nth-child(odd) {
  transform: translateY(300px);
}

.roadmap .slick-active.slick-current {
  margin-left: auto;
}

.roadmap .slick-slide:nth-child(even) .box::before {
  bottom: -15px;
  top: unset;
}

.roadmap .slick-slide:nth-child(even) .box {
  display: flex;
  flex-direction: column-reverse;
}

.roadmap .sliderbg {
  position: relative;
}

.roadmap .sliderbg::before {
  content: "";
  position: absolute;
  opacity: 0.10000000149011612;
  background: linear-gradient(248deg, #f78e0e 0%, #c50c0c 100%);
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  height: 8px;
  width: 100%;
}

.roadmapmob {
  background: #f7f9fc;
  padding: 40px 0;
}
.roadmapmob .ctn {
  font-family: Sk-Modernist;
  font-size: 14px;
  margin-bottom: 30px;
}
.roadmapmob .abv_slide {
  margin-bottom: 30px;
}
.roadmapmob .abv_slide .box {
  color: #1d1d1d;
  border-radius: 100px;
  padding: 8px 15px;
  border: 1px solid rgba(128, 128, 128, 0.6);
  margin-right: 10px;
  text-align: center;
  color: #1d1d1d;
  font-family: Space Grotesk;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}
.roadmapmob .abv_slide .slick-active.slick-current .box {
  background: #1d1d1d;
  color: #fff;
}

.roadmapmob .blw_slide .box {
  /* border-left: 1px solid #F78E0E; */
  padding-left: 20px;
  padding-bottom: 10px;
  position: relative;
  margin-left: 20px;
  margin-bottom: -10px;
}
.roadmapmob .blw_slide .box::before {
  content: "";
  position: absolute;
  background: radial-gradient(#f78e0e 37%, #f78e0e45 40%) no-repeat;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  top: -5px;
  left: -15px;
}
.roadmapmob .blw_slide .box.now::before {
  background: radial-gradient(#f78e0e 37%, #63636300 40%) no-repeat !important;
  box-shadow: 0 0px 10px 0 #f78e0e !important;
}
.roadmapmob .blw_slide .box {
  color: #1d1d1d;
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
}
.roadmapmob .blw_slide .box .end {
  font-weight: 600;
}
/* .roadmapmob .blw_slide .box.now .end {
  position: absolute;
  top: -30px;
  left: -10px;
} */
.roadmapmob .blw_slide .box h6 {
  color: #1d1d1d;
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
/* .roadmapmob .blw_slide .slick-slide{
  padding-top: 20px;
} */
.roadmapmob .blw_slide .slick-slide:nth-child(1) {
  padding-top: 30px;
}
.roadmapmob .blw_slide .slick-slide:nth-child(4) {
  padding-top: 40px;
}

.roadmapmob .blw_slide .slick-list::before {
  content: "";
  background: #f78e0e;
  left: 20px;
  position: absolute;
  height: 100%;
  width: 1px;
  top: 35px;
}

.faq {
  padding: 50px 0;
  counter-reset: my-sec-counter;
}

.faq .accordion-item {
  border: 1px solid rgba(29, 29, 31, 0.5);
  background: #f7f9fc;
  margin-bottom: 30px;
  border-radius: 0;
}

.faq .accordion-button {
  box-shadow: none !important;
  background: transparent;
  color: rgba(29, 29, 31, 0.8);
  font-size: 24px;
  font-family: Sk-Modernist;
  align-items: flex-start;
}

.faq .accordion-button::before {
  counter-increment: my-sec-counter;
  content: counter(my-sec-counter) ". ";
  padding-right: 8px;
}

.faq .accordion-button::after {
  background: url("./assets/images/faqarw.png") no-repeat center;
  width: 35px;
  background-size: contain;
  height: 25px;
  font-size: 0;
}

.footer {
  border-top: 1px solid rgba(29, 29, 29, 0.5);
  padding-top: 30px;
  margin-top: 50px;
}

.footer .brand_logo {
  width: 160px;
}

.footer ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  width: fit-content;
  margin-left: auto;
}

.footer li {
  margin-right: 30px;
}

.footer li:nth-last-child(1) {
  margin-right: 0;
}

.footer li a {
  color: #1d1d1d !important;
  font-family: Space Grotesk;
  font-size: 22px;
  text-decoration: none;
  cursor: pointer;
}

.footer .footer_blw {
  margin-top: 60px;
  color: rgba(29, 29, 31, 0.8);
  font-family: Sk-Modernist;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 10px;
}

.footer .footer_blw li a {
  color: rgba(29, 29, 31, 0.8);
  font-family: Sk-Modernist;
  font-size: 20px;
}

.footer .footer_blw .flx {
  display: flex;
  justify-content: space-between;
  max-width: 80%;
  margin: 0 auto;
  border-top: 1px solid rgba(29, 29, 29, 0.5);
  padding-top: 10px;
}
.buytoken_confirm_body svg {
  width: 70px;
}
/* Connect Wallet */
.primary_modal .modal-content {
  background: linear-gradient(132deg, #ffffff, #ffffff);
}

.primary_modal .modal-header {
  border-bottom: 1px solid #e0e0e0;
  padding: 20px 24px 15px;
}

.connect_wallet_button {
  border: 2px solid #e0e0e0;
  background-color: transparent;
  color: #000;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
}

.connect_wallet_button img {
  height: 31px;
}

.primary_modal .btn-close {
  filter: invert(0%) sepia(2%) saturate(37%) hue-rotate(204deg) brightness(109%)
    contrast(101%);
}

.connect_wallet_button:hover {
  border-color: #e0e0e0;
}

.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #000;
}

.connect_wallet_button img {
  margin-right: 15px;
}

.connect_wallet_button span {
  color: #000;
  font-size: 22px;
  font-weight: 400;
}

.btn-close {
  width: 32px;
  height: 32px;
  color: #000;
  border: 0;
  /* background: transparent url("../src/assets/images/close_icon.png") no-repeat; */
  opacity: 0.8;
  outline: none;
  box-shadow: none !important;
}

.btn-close:hover {
  opacity: 1;
}

.primary_modal a {
  text-decoration: none;
}
.buytoken_confirm_body div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.buytoken_confirm_body div label:nth-child(2) {
  font-weight: 600;
}
.buytoken_confirm_body_chg div{
  flex-direction: column;
}
.buytoken_confirm_body_chg div .tic{
  font-size: 67px;
}

/**** tick symbol withdraw******/
/* svg {
  width: 80px;
  display: block;
  margin: 23px auto 0;
} */

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  &.circle {
    -webkit-animation: dash .9s ease-in-out;
    animation: dash .9s ease-in-out;
  }
  &.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash .9s .35s ease-in-out forwards;
    animation: dash .9s .35s ease-in-out forwards;
  }
  &.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check .9s .35s ease-in-out forwards;
    animation: dash-check .9s .35s ease-in-out forwards;
  }
}

p {
  &.success {
    color: #73AF55;
  }
  &.error {
    color: #D06079;
  }
}
.footer_blw p {
  margin-bottom: 0;
  font-size: 16px;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
/**** tick symbol withdraw******/

.img_sec {
  padding: 50px 0;
}
.img_sec img {
  width: 100%;
}

@media (max-width: 1399px) {
  
  .main_navbar ul li {
    margin-right: 20px;
}
  .page_header .header_box,
  .table_sec .table_size {
    max-width: 100%;
  }
  .footer li {
    margin-right: 20px;
  }
  .main_navbar ul li a {
    font-size: 17px;
  }
  .navbar_right button.primary_btn {
    font-size: 17px;
    padding: 10px 20px;
  }
}

@media (max-width: 1199px) { 
  .main_navbar .navbar_right {
    flex-direction: column;
  }

  .main_navbar ul {
    flex-direction: column;
  }

  .main_navbar ul li {
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .timer_panel {
    padding: 0;
  }

  .h2tag {
    font-size: 38px;
  }

  .page_header h1 {
    font-size: 42px;
    max-width: 100%;
  }

  .page_header .banimg {
    max-width: 55%;
    margin: 20px 0;
  }

  .page_header .presale p {
    font-size: 24px;
  }

  .page_header .presale .comingsoon {
    font-size: 16px;
  }

  .about p {
    font-size: 18px;
  }

  .howbuy li p {
    font-size: 18px;
  }

  .faq .accordion-button {
    font-size: 20px;
  }

  .footer li {
    margin-right: 19px;
  }

  .footer li a {
    font-size: 18px;
  }

  .footer .footer_blw li a,
  .footer .footer_blw {
    font-size: 16px;
  }

  .footer .footer_blw .flx {
    max-width: 100%;
  }

}

@media (max-width: 991px) {
  .pc_view {
    display: none;
  }

  .mob_view {
    display: inline-block;
  }
  .timer_panel {
    padding: 0 120px 30px;
  }

  .timer_panel::before {
    display: none;
  }
  .page_header .header_box .flx_head {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .page_header .presale {
    max-width: 100%;
  }
  .footer .align{
    flex-direction: column;
   }

  .footer ul {
    margin-left: 0;
    margin-top: 30px;
   
  }

  .footer .footer_blw {
    margin-top: 30px;
  }
 

  .footer .footer_blw .flx {
    flex-direction: column;
  }

  .footer .footer_blw .flx p {
    margin-top: 30px;
  }

  .howbuy li {
    max-width: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .howbuy li span {
    margin: 0 0 15px;
    font-size: 20px;
  }

  .chart ul {
    display: block;
  }


  .roadmap .ctn {
    max-width: 100%;
  }

  .roadmap h2 {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .faq .accordion-button::before {
    padding-right: 5px;
  }
  span.timer_dots {
    font-size: 38px;
  }

  .timer_time {
    font-size: 42px;
  }

  .timer_panel {
    padding: 0 50px 30px;
  }
  .page_header .header_box .flx_head {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .page_header .presale {
    flex-direction: column;
    border-radius: 10px;
  }

  .page_header .presale .comingsoon {
    margin-top: 140px;
  }

  .faq .accordion-button {
    font-size: 16px;
  }

  .about {
    text-align: center;
  }
  .roadmapmob .abv_slide .slick-slide {
    width: 100px !important;
  }
  .roadmapmob .abv_slide .slick-track {
    margin-left: unset;
    display: flex;
    overflow-x: scroll;
    width: 100% !important;
    padding-bottom: 8px;
  }
  .page_header .header_box .form-group {
    width: 100%;
  }
  .img_sec {
    padding: 30px 0;
  }
}

@media (max-width: 575px) {
  .h2tag {
    font-size: 27px;
    padding-bottom: 15px !important;
    margin-bottom: 10px !important;
  }
  span.timer_label {
    font-size: 12px;
  }
  .timer_time {
    font-size: 28px;
  }
  span.timer_dots {
    font-size: 27px;
  }
  .page_header .header_box h3 {
    font-size: 26px;
  }
  .page_header .header_box .para {
    font-size: 14px;
  }
  .page_header .header_box p,
  .page_header .header_box .primary_btn {
    font-size: 14px;
  }
  .page_header .header_box .input-group-text {
    font-size: 14px;    
  }
  .page_header .header_box .form-control {
    padding-right: 40px;
    z-index: 1;
    font-size: 14px;
  }
  .timer_panel {
    padding: 0 10px 30px;
    justify-content: center;
  }

  .page_header .header_box .right_box {
    padding: 0 0px;
  }
  .page_header .header_box .right_box .flx {
    flex-direction: column;
  }
  .timer_panel > span {
    margin: 0px 5px 0 0;
  }

  .page_header .header_box {
    padding: 20px;
  }

  .howbuy ul {
    padding-top: 0;
  }

  .howbuy li p {
    font-size: 14px;
  }

  .howbuy li span {
    font-size: 14px;
    min-width: 22px;
    height: 22px;
  }

  .page_header h1 {
    font-size: 32px;
  }

  .about img {
    max-width: 60%;
  }
  .about p {
    font-size: 14px;
  }
  .page_header .banimg {
    margin: 50px 0;
  }

  .page_header {
    padding-bottom: 0;
  }

  .howbuy,
  .chart {
    padding: 0;
  }

  .chart li {
    margin-bottom: 15px;
  }

  .chart li p {
    font-size: 14px;
  }

  .chart li p span {
    font-size: 12px;
  }

  .roadmap {
    padding-bottom: 0;
  }

  .footer li a {
    font-size: 14px;
  }

  .footer .footer_blw li a
  {
    font-size: 20px;
  }
  .footer .footer_blw {
    text-align: center;
  }
  .faq .accordion-body {
    font-size: 14px;
  }
  .footer ul{
    margin-top: 15px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .faq{
    padding-bottom: 0;
  }
  .main_navbar .mobileSocialNavbar{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .mobileSocialNavbar li{
    margin: 0 15px !important;
  }
  .main_navbar .navbar_right{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 80vh;
}
}
@media (max-width: 400px) {
  .brand_logo {
    width: 100px !important;
  }
  .roadmapmob .abv_slide {
    margin-bottom: 30px;
    z-index: 2;
    background: #f7f9fc;
  }
  .roadmapmob .blw_slide .slick-slide:nth-child(1) {
    padding-top: 30px !important;
  }
  .roadmapmob .blw_slide .slick-slide:nth-child(4) {
    padding-top: 50px !important;
  }
  .roadmapmob .blw_slide .slick-slide {
    padding-top: 15px !important;
  }
}
.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  fill: rgb(0, 16, 247) !important;
  margin-left: -32px;
  margin-top: -32px;
  z-index: 99999;
}
.loading::before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(245, 242, 242, 0.25);
  z-index: 1500;
  overflow: hidden;
}
